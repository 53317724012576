<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('itemIncentiveBonus.bonusItem')
                }}</label>
                <div>
                    <input
                        v-model="model.inc_bonus_item"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('inc_bonus_item')
                        }"
                    />
                </div>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('inc_bonus_item')"
                >
                    {{ errors.first('inc_bonus_item') }}
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label tw-text-xs">{{
                    $t('itemIncentiveBonus.customRule')
                }}</label>
                <div>
                    <Select v-model="model.custom_keyword">
                        <Option
                            v-for="(itemCustomRule, index) in itemCustomRules"
                            :value="itemCustomRule.keyword"
                            :key="index"
                            :label="itemCustomRule.custom_rule"
                            >{{ itemCustomRule.custom_rule }}
                        </Option>
                    </Select>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label tw-text-xs">{{
                    $t('itemIncentiveBonus.description')
                }}</label>
                <div>
                    <Input
                        v-model="model.description"
                        type="textarea"
                        :rows="5"
                        placeholder="Enter something..."
                    />
                </div>
            </div>
            <div class="mb-3">
                <Checkbox v-model="model.is_taxable">{{
                    $t('itemIncentiveBonus.taxable')
                }}</Checkbox>
            </div>
            <div class="mb-3">
                <Checkbox v-model="model.is_prorate ">{{
                    $t('itemIncentiveBonus.prorate')
                }}</Checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'item-incentive-bonus-create',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                inc_bonus_item: null,
                is_taxable: false,
                description: null,
                custom_keyword: null,
                is_prorate:false
            }
        }
    },
    computed: {
        ...mapState('payroll/itemIncentiveBonus', ['edit_data']),

        itemCustomRules () {
            return this.$store.state.payroll.itemIncentiveBonus.itemCustomRules
        },
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        ...mapActions('payroll/itemIncentiveBonus', ['getItemCustomRule']),
        fetchResource () {
            this.loading = true
            this.getItemCustomRule()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/itemIncentiveBonus/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/itemIncentiveBonus/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/itemIncentiveBonus/update', {
                    id: this.edit_data.inc_bonus_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.inc_bonus_item = this.edit_data.inc_bonus_item
                this.model.custom_keyword = this.edit_data.custom_keyword
                this.model.is_taxable = this.edit_data.is_taxable
                this.model.description = this.edit_data.description
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.inc_bonus_item = null
            this.model.is_taxable = false
            this.model.description = null
            this.model.custom_keyword = null
            this.model.is_prorate = false
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'ITEM INCENTIVE BONUS',
                desc: not.text
            })
        }
    }
}
</script>
